import { useEffect, useRef, useState } from 'react'
import {
	DDIAddressDisplayList,
	DDIAddressUpdateModel,
	DDIListResponse,
} from '../../../../../../../utils/interfaces/APIModels'
import { AddressMap } from '../../../../../../../utils/interfaces/DBModels'
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import './TNAddressUpdate.scss'
import { StyledSelect } from '../../../../../../shared/styledComponents/StyledSelect/StyledSelect'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { StyledTextBox } from '../../../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import UseCrud from '../../../../../../../utils/customHooks/APICalls/UseCrud'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/store'
import { DDIStatuses } from '../../../../../../../utils/enums/enums'

const TNAddressUpdate = ({
	customerAddressList,
	selectedDDIAddress,
	handleCloseModal,
}: {
	customerAddressList: AddressMap[]
	selectedDDIAddress: DDIAddressDisplayList
	handleCloseModal?: any
}) => {
	var loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	// Hooks
	const { modifyData } = UseCrud()

	const [addressMapID, setAddressMapID] = useState(0)
	const [disableAssignAddress, setDisableAssignAddress] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const originalLocation = useRef('')
	const addressID = useRef<number>(0)

	useEffect(() => {
		if (selectedDDIAddress !== undefined) {
			const _tnLocation = selectedDDIAddress.TNLocation?.includes(',')
				? selectedDDIAddress.TNLocation.split(',')[1].trim()
				: selectedDDIAddress.TNLocation
			const _addressMapID = customerAddressList.find(
				(address) => address.Address?.AddressLine1 === _tnLocation
			)
			originalLocation.current = _tnLocation + ''
			setAddressMapID(Number(_addressMapID?.AddressMapID ?? 0))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getAddressMap = (_addressMapID: number): AddressMap | undefined => {
		return customerAddressList.find(
			(addressMap: AddressMap) => addressMap.AddressMapID === _addressMapID
		)
	}

	const handleAddressChange = (e: { target: any }) => {
		const addressMapID = Number(e.target.value)
		const addressMap = getAddressMap(addressMapID)

		setAddressMapID(addressMapID)

		if (addressMap !== undefined) {
			if (addressMap.Address?.AddressLine1 === originalLocation.current) {
				setDisableAssignAddress(true)
				return
			}
		}

		setDisableAssignAddress(false)
	}

	const handleAssignAddress = () => {
		const addressMap = getAddressMap(addressMapID)
		addressID.current = Number(addressMap?.AddressID)

		if (addressMap?.AddressID) {
			setIsLoading(true)
			assignAddressToDDIRange()
		}
	}

	const assignAddressToDDIRange = async () => {
		const ddis: DDIAddressUpdateModel[] = []

		if (selectedDDIAddress.DDIList !== undefined) {
			const ddiList: DDIListResponse[] = selectedDDIAddress.DDIList

			ddiList.forEach((ddi: DDIListResponse) => {
				ddis.push({
					ID: ddi.ID,
					AddressID: addressID.current,
					DDIStatusID: DDIStatuses.Validation,
				})
			})
		}

		var ddiReqObj = {
			DDIList: ddis,
		}

		const dataResponse = (await modifyData({
			FileAndFunctionName: 'TNAddressUpdate.tsx assignAddressToDDIRange()',
			QueryURL: `UpdateV2?Params=DDI:list`,
			QueryObj: ddiReqObj,
			ErrorMessage: 'Could not add address to DDIRange',
			ShowErrorMessage: true,
			ShowSuccessMessage: true,
			SuccessMessage: 'Address added to DDI Range successfully',
			LogErrorToDB: false,
			UserName: loggedInUser.email,
		})) as Boolean

		if (dataResponse) {
			handleCloseModal()
		}
		setIsLoading(false)
	}

	return (
		<>
			<Box className='tn-address-update-container'>
				{/* Heading */}
				<Box className='tn-address-update-header'>
					<img
						src={require('../../../../../../../assets/icons/addTNs/AddTNsIcon.png')}
						alt='Add TNs Icon'
					/>
					<Typography
						id='order-address-header'
						component='h1'
						style={{ fontSize: '25px' }}>
						Add TN Location
						<Typography component='h4'>Select a TN Location Below:</Typography>
					</Typography>
					<Box className='modal-close-button'>
						<IconButton onClick={handleCloseModal}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>

				<Divider />
				<Box className='tn-address-update-content'>
					{/* TN Start */}
					<Box className='tn-address-update-input'>
						<Typography component='p'>TN Start:</Typography>
						<StyledTextBox
							fullWidth
							disabled
							className='input-field'
							variant='outlined'
							value={selectedDDIAddress.DDIRangeStart}
							type='text'
							style={{ backgroundColor: 'white' }}
						/>
					</Box>
					{/* TN End */}
					<Box className='tn-address-update-input'>
						<Typography component='p'>TN End:</Typography>
						<StyledTextBox
							fullWidth
							disabled
							className='input-field'
							variant='outlined'
							value={selectedDDIAddress.DDIRangeEnd}
							type='text'
							style={{ backgroundColor: 'white' }}
						/>
					</Box>
					{/* Country */}
					<Box className='tn-address-update-input'>
						<Typography component='p'>Country:</Typography>
						<StyledTextBox
							fullWidth
							disabled
							className='input-field'
							variant='outlined'
							value={selectedDDIAddress.CountryName}
							type='text'
							style={{ backgroundColor: 'white' }}
						/>
					</Box>
					{/* Location */}
					<Box className='tn-address-update-input'>
						<Typography component='p'>
							Location: <span style={{ color: 'red' }}>*</span>
						</Typography>
						<StyledSelect
							fullWidth
							required
							variant='outlined'
							className='input-field'
							type='text'
							onChange={handleAddressChange}
							value={addressMapID}>
							<MenuItem disabled value={0}>
								Choose a TN location
							</MenuItem>
							{customerAddressList.map((item, index) => {
								return (
									<MenuItem key={index} value={item.AddressMapID}>
										{item.AddressName
											? item.AddressName
											: item.Address?.AddressLine1}
									</MenuItem>
								)
							})}
						</StyledSelect>
					</Box>
				</Box>
				{/* Footer */}
				<Divider />
				<Box className='tn-address-update-footer'>
					<Tooltip
						title={
							disableAssignAddress
								? 'Select a location to assign'
								: 'Assign Address'
						}>
						<span>
							<LoadingButton
								disabled={disableAssignAddress}
								loadingPosition='center'
								loading={isLoading}
								startIcon={<SaveIcon />}
								onClick={handleAssignAddress}>
								Assign Address
							</LoadingButton>
						</span>
					</Tooltip>
				</Box>
			</Box>
		</>
	)
}

export default TNAddressUpdate
